import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import { getTestStatusType } from "util/Partner";
import { packageStatuses, packageStatusTypes } from "util/Packages";
import { DATE_FORMAT } from "util/DateTime";
import "./Table.scss";


class PackagesTable extends React.Component {
    static propTypes = {
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,

        sorting: PropTypes.object.isRequired,
        onTableChange: PropTypes.func.isRequired,
    }

    render() {
        if (this.props.packagesListLoading) {
            return (
                <WrapperLoader />
            );
        }
        if (!this.props.packagesList) {
            return <></>;
        }

        const emptyTableIndication = this.renderCenteredContent(<p className="my-3 regular-14">Ükski pakend ei vastanud otsingutingimustele.</p>);

        const columns = [
            {
                dataField: "package_id",
                text: "ID",
                classes: "semibold-14",
                sort: true,
                hidden: true,
            },
            {
                dataField: "ean",
                text: "EAN kood",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "ean_type.label",
                text: "EAN tüüp",
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "name",
                text: "Toote nimetus",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "client.label",
                text: "Klient",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "color",
                text: "Värv",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "category.label",
                text: "Kategooria",
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "type.label",
                text: "Tüüp",
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "volume",
                text: "Maht",
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "weight",
                text: "Kaal",
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "status.label",
                text: "Staatus",
                classes: "regular-14",
                align: "center",
                headerAlign: "center",
                sort: true,
                formatter: (statusLabel, row) => (
                    <StatusCell
                        status={row.status.label}
                        type={packageStatusTypes[row.status.value]}
                        className="d-inline-flex"
                    />
                ),
            },
            {
                dataField: "created_at",
                text: "Loodud",
                classes: "regular-14 created-at-col",
                sort: true,
                formatter: (date) => moment(date).format(DATE_FORMAT),
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "last_test.status",
                text: "Testitulemus",
                align: "center",
                headerAlign: "center",
                sort: true,
                formatter: (lastTestStatus) => (
                    <StatusCell
                        status={lastTestStatus}
                        type={getTestStatusType(lastTestStatus)}
                        className="d-inline-flex no-background"
                    />
                ),
            },
        ];

        const rowClasses = (row, rowIndex) => {
            const classes = ["pointer"];
            if (row.status.value === packageStatuses.LOCAL_DELETE) {
                classes.push("color-gray-lightest-bg");
            }
            return classes.join(" ");
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.onRowClick(e, row);
            },
        };

        const defaultSorted = [
            this.props.sorting,
        ];

        return (
            <BootstrapTable
                remote
                bootstrap4
                bordered={false}
                classes="admin-all-packages-table border-bottom"
                keyField="package_id"
                data={this.props.packagesList}
                columns={columns}
                noDataIndication={emptyTableIndication}
                defaultSorted={defaultSorted}
                onTableChange={this.props.onTableChange}
                wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                rowClasses={rowClasses}
                rowEvents={rowEvents}
            />
        );
    }

    renderCenteredContent = content => {
        return (
            <CenteredRowCol>
                {content}
            </CenteredRowCol>
        );
    }
}

export default PackagesTable;
